<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">

              <!-- Header: Left Content -->
              <div class="d-flex">
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                    alt="logo"
                  />
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="invoice-date-wrapper mb-0">
                  <p
                    class="invoice-date-title"
                    style="width:130px"
                  >
                    Request date:
                  </p>
                  <p class="invoice-date">
                    {{ request_date }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date-title"
                    style="width:130px"
                  >
                    Payment date:
                  </p>
                  <p class="invoice-date">
                    <flat-pickr
                      v-model="payment_date"
                      class="form-control invoice-edit-input"
                      :config="config"
                    />
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <b-row class="customrow">

            <b-col cols="6">

              <table class="info-table">
                <tr>
                  <td class="td-field">
                    Name
                  </td><td class="td-value">
                    : {{ name }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Email
                  </td><td class="td-value">
                    : {{ email }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Amount Request
                  </td><td class="td-value">
                    : <b class="text-success">{{ amount }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Status
                  </td>
                  <td class="td-value">
                    <b-form inline>:
                      <b-form-checkbox
                        value="1"
                        :checked="status"
                        switch
                        @change="changeStatus"
                      />
                      {{ status_text }}
                    </b-form>
                  </td>
                </tr>
              </table>
            </b-col>
            <b-col cols="6">
              <table
                v-if="payment_method==='Wire Transfer'"
                class="info-table"
              >
                <tr>
                  <td class="td-field">
                    Payment method
                  </td><td class="td-value">
                    : {{ payment_method }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Account holder name
                  </td><td class="td-value">
                    : {{ account_holder_name }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Account holder address
                  </td><td class="td-value">
                    : {{ account_holder_address }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Bank Name
                  </td><td class="td-value">
                    : {{ bank_name }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    IBAN
                  </td><td class="td-value">
                    : {{ iban }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    SWIFT
                  </td><td class="td-value">
                    : {{ swift }}
                  </td>
                </tr>
              </table>

              <table
                v-else-if="payment_method==='Paypal'"
                class="info-table"
              >
                <tr>
                  <td class="td-field">
                    Payment method
                  </td><td class="td-value">
                    : {{ payment_method }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Payment account
                  </td><td class="td-value">
                    : {{ paypal_account }}
                  </td>
                </tr>
              </table>

              <table
                v-else-if="payment_method==='Bitcoin'"
                class="info-table"
              >
                <tr>
                  <td class="td-field">
                    Payment method
                  </td><td class="td-value">
                    : {{ payment_method }}
                  </td>
                </tr>
                <tr>
                  <td class="td-field">
                    Payment account
                  </td><td class="td-value">
                    : {{ bitcoin }}
                  </td>
                </tr>
              </table>

            </b-col>
          </b-row>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            @click="Save"
          >
            Save
          </b-button>

          <!-- Button: download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="downloadUserInvoice(invoice_path)"
          >
            Download
          </b-button>

          <!-- Button: refuse payment -->
          <b-button
            v-show="!status"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="RefusePayment"
          >
            Refuse payment
          </b-button>

          <!-- Button: cancel -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="goBack"
          >
            Go Back
          </b-button>

        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="invoice-preview"
    >
      <b-col
        cols="12"
      >
        <affiliate-revenue-detail
          v-if="commission_id>0"
          :record-type="3"
          :com-i-d="commission_id"
          :is-admin="1"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BImg, VBToggle, BFormCheckbox, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { $themeConfig } from '@themeConfig'
import axios from '@/libs/axios'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import AffiliateRevenueDetail from '../../affiliate/AffiliateRevenueDetail.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BTableLite,
    BButton,
    BImg,
    // BCardText,
    // BFormInput,
    // BFormGroup,
    BFormCheckbox,
    flatPickr,
    AffiliateRevenueDetail,
    BForm,
  },

  data() {
    return {
      commission_id: 0,
      request_date: '',
      payment_date: '',
      name: '',
      email: '',
      amount: '',
      payment_method: '',
      iban: '',
      swift: '',
      bank_name: '',
      account_holder_name: '',
      account_holder_address: '',
      status: 0,
      status_text: 'pending',
      invoice_path: '',
      paypal_account: '',
      bitcoin: '',
      config: {
        dateFormat: 'd M Y',
      },
    }
  },
  created() {
    this.fetchAffiliatePaymentsById()
  },

  methods: {
    async fetchAffiliatePaymentsById() {
      const { id } = this.$route.params

      const res = await axios.get(`/api/affiliates/get_payments/${id}`)
      if (res.status === 200) {
        const { data } = res
        this.request_date = moment(new Date(data.created_at)).format('DD MMM YYYY')
        this.payment_date = moment(new Date(data.updated_at)).format('DD MMM YYYY')
        // this.payment_date = data.updated_at
        this.name = `${data.forename} ${data.surname}`
        this.email = data.email
        this.amount = `€ ${parseFloat(data.commission_paid).toFixed(2)}`
        this.payment_method = data.payment_method
        this.iban = data.iban
        this.swift = data.swift
        this.bank_name = data.bank_name
        this.account_holder_name = data.acc_holder_name
        this.account_holder_address = data.acc_holder_address
        this.paypal_account = data.paypal_account
        this.bitcoin = data.bitcoin
        this.status = Number(data.payment_status)
        this.status_text = 'pending'
        this.invoice_path = data.invoice_path
        if (this.status) this.status_text = 'paid'
        this.commission_id = data.id
      }
    },
    async Save() {
      const { id } = this.$route.params
      const payload = {
        id,
        updated_at: this.payment_date,
        status: this.status,
      }
      const res = await axios.post('/api/affiliates/update_payments', payload)

      if (res.status === 200) {
        this.notify({
          text: 'Payment request is save.',
          variant: 'succes',
        })
        this.goBack()
      }
    },
    async RefusePayment() {
      const { id } = this.$route.params
      const payload = { id }
      const res = await axios.post('/api/affiliates/refuse_payment', payload)
      if (res.status === 200) {
        this.notify({
          text: 'Payment request is refused.',
          variant: 'succes',
        })
        this.goBack()
      }
    },
    goBack() {
      // this.$router.push('/billing/affiliate-payments')
      window.location = '/billing/affiliate-payments'
    },

    downloadUserInvoice(invoice) {
      const filename = invoice.split('/')[1]
      axios({
        url: `/api/affiliates/download_user_invoice?invoice=${invoice}`,
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(res.data)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        link.id = 'download_to_download'
        document.body.appendChild(link)
        document.getElementById(link.id).click()

        // remove to make sure a new csv file will be downloaded and not the previous one
        const element = document.getElementById(link.id)
        element.remove()
      })
    },
    changeStatus() {
      if (this.status) this.status = 0
      else this.status = 1
      this.status_text = 'pending'
      if (this.status) this.status_text = 'paid'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}
.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}
.info-table{
  width:100%
}
.info-table td{
  height: 35px;
}
.td-field{
  width:40%;
}
.td-value{
  width:60%;
  font-weight: bold;
}
.info-table tr{
  border-bottom: 1px solid #cccccc
}
.info-table{
  margin-bottom: 10px
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

.customrow{
    padding: 0px 20px 0px 20px;
}

.invoice-date-wrapper{
    width: 300px;
}
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }

}
</style>
